import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isEmpty, getFileImg, getBase64 } from '../../../Utils';

import { UploadInfoPoints } from '../../../components/custom';
import { Form } from '../../../components/form';
import { Button } from '../../../components/general';
import { ConfirmModal, ErrorModal } from '../../../components/modals';
import { DraggerSimplified } from '../../../components/video';

import { URL } from '../../../constants';

import { checkIn } from '../../../services/checkInService';

import { getDetailsText } from './checkinText';
import DetailsSection from './detailsSection';

const DetailsStage = (props) => {
  const { event, face, isPreview } = props;

  const [name, setName] = useState(face.name ?? '');
  const [instagram, setInstagram] = useState(face.instagram ?? '');
  const [customFieldData, setCustomFieldData] = useState(
    face.customFieldData ?? '',
  );
  const [selfieFile, setSelfieFile] = useState(null);
  const [selfieUrl, setSelfieUrl] = useState(
    face.photos[0] ? getFileImg(face.photos[0]) : '',
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isPreview) return;

    setIsProcessing(true);
    setShowConfirmModal(true);

    try {
      const faceData = {
        elementId: face.elementId,
        name,
        instagram,
        customFieldData,
      };

      await checkIn({
        eventId: event.eventId,
        faceData,
        photo: selfieFile,
      });
    } catch (e) {
      setError(e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const onHideModal = () => {
    setShowConfirmModal(false);
    navigate(`${URL.CHECK_IN}${event.eventId}`);
    window.location.reload();
  };

  const changeSelfie = (file) => {
    getBase64(file, (url) => setSelfieUrl(url));
    setSelfieFile(file);
  };

  const { selfieInfoPoints, tipsList } = getDetailsText();

  return (
    <React.Fragment>
      <Form onSubmit={onSubmit}>
        <h5>
          <b>Step 1</b>: Fill in your details
        </h5>

        <DetailsSection
          event={event}
          name={name}
          setName={setName}
          instagram={instagram}
          setInstagram={setInstagram}
          customFieldData={customFieldData}
          setCustomFieldData={setCustomFieldData}
        />

        <hr className="my-4" />

        <h5>
          <b>Step 2</b>: Upload a photo or a selfie*
        </h5>

        <DraggerSimplified
          id="ci-photo-upload"
          type="image"
          heading="Photo upload (1 max)"
          imgUrl={selfieUrl}
          onDrop={changeSelfie}
          isPreview={isPreview}
        />

        <UploadInfoPoints points={selfieInfoPoints} />

        <Button
          disabled={isEmpty(name) || isEmpty(selfieUrl)}
          isSubmitBtn
          isProcessing={isProcessing}
        >
          Submit
        </Button>
      </Form>

      <ConfirmModal
        show={showConfirmModal}
        isProcessing={isProcessing}
        heading={
          isProcessing
            ? "We're updating your details"
            : `Great! You have successfully checked in to ${event.name}, ${name}.`
        }
        subheading={
          !isProcessing &&
          'Feel free to close this page, and keep an eye on your inbox for your personalised video.'
        }
        tipsList={tipsList}
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </React.Fragment>
  );
};

export default DetailsStage;
